<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <h2 class="mb-4 cursive-font">Welcome back, {{ userData.username }}</h2>

    <div v-if="state.isLoading" class="d-flex mb-4">
      <v-progress-circular
        class="mx-auto"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="d-flex flex-row flex-wrap mb-2">
      <div class="col-4">
        <v-card elevation="2">
          <v-card-title>Clients</v-card-title>
          <v-card-text>
            Total: <b>{{ state.stats.clients.total }}</b> <br />
            Subscribed: <b>{{ state.stats.clients.subscribed }}</b> <br />
            Not Subscribed: <b>{{ state.stats.clients.notSubscribed }}</b>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-4">
        <v-card elevation="2">
          <v-card-title>Virtual Assistants</v-card-title>
          <v-card-text>
            Total: <b>{{ state.stats.vas.total }}</b> <br />
            Assigned To Clients: <b>{{ state.stats.vas.assigned }}</b> <br />
            Available: <b>{{ state.stats.vas.available }}</b>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-4">
        <v-card elevation="2">
          <v-card-title>Active Projects</v-card-title>
          <v-card-text>
            Total: <b>{{ state.stats.projects.total }}</b> <br />
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-btn
      @click="navigateToRoute('conversations')"
      color="primary"
      outlined
      class="align-self-start mb-4"
    >
      View Conversations
      <v-icon class="ml-2">{{ icons.mdiChevronRight }}</v-icon>
    </v-btn>

    <v-btn
      @click="navigateToRoute('manage-assignment')"
      color="primary"
      outlined
      class="align-self-start mb-4"
    >
      Manage Assignments
      <v-icon class="ml-2">{{ icons.mdiChevronRight }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from "@axios";
import { useUser } from "@/composables/user/user";
import { reactive, onMounted } from "@vue/composition-api";
import { mdiChevronRight } from "@mdi/js";

export default {
  name: "Dashboard",
  setup() {
    const { userData } = useUser();

    const state = reactive({
      isLoading: false,
      stats: {},
    });

    onMounted(() => fetchStats());

    async function fetchStats() {
      try {
        state.isLoading = true;
        const { data } = await axios.get("/admin/dashboard-stats");
        state.stats = data;
      } catch (err) {
        console.log(err);
      } finally {
        state.isLoading = false;
      }
    }

    function navigateToRoute(route) {
      this.$router.push({ name: route });
    }

    return {
      state,
      userData,
      navigateToRoute,

      icons: {
        mdiChevronRight,
      },
    };
  },
};
</script>
